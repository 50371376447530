<template>
  <div class="signin-container">
    <el-form ref="signin" :model="form" label-width="80px">
      <el-form-item label="角色">
        <el-select
          v-model="form.role"
          placeholder="请选择角色"
          style="width: 100%"
        >
          <el-option label="用户" value="user"></el-option>
          <el-option label="服务商" value="servicer"></el-option>
          <el-option label="管理员" value="admin"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户名">
        <el-input v-model="form.username"></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-input type="password" v-model="form.password"></el-input>
      </el-form-item>
      <el-form-item class="signin-btn">
        <el-button type="primary" @click="signin">登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
    };
  },
  methods: {
    signin() {
      let model = JSON.parse(JSON.stringify(this.$refs["signin"].model));
      // console.log(model);
      if (model.role === "user") {
        localStorage.setItem("customerId", "1");
        this.$router.push("/user/order");
      } else if (model.role === "servicer") {
        localStorage.setItem("serviceId", "1");
        this.$router.push("/servicer/printer");
      } else if (model.role === "admin") {
        this.$router.push("/admin/user");
      }
    },
  },
};
</script>

<style lang="scss">
.signin-container {
  margin-top: 2rem;
  .signin-btn {
    text-align: center;
    .el-form-item__content {
      margin-left: 0 !important;
    }
  }
}
</style>